<template>
    <form v-if="form " class="space-y-6 w-full">

        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 w-full">

            <div class="col-span-3">
                <Input type="date"  class="md:w-1/3" placeholder="Rēķina datums" v-model="form.full_date" :errors="errors.date"/>
            </div>


            <div class="col-span-1">
                <Radio label="Valoda:" name="lang" v-model="form.lang"
                       :items="[{name: 'LV', value: 'lv'}, {name: 'ENG', value: 'en'}]"
                       :errors="errors.lang"/>
            </div>


            <div class="col-span-1 flex items-center">
                <Checkbox text="Sagat. elektroniski" v-model="form.electronic_document"/>
            </div>

            <div class="col-span-1 flex items-center">
                <Checkbox text="Reversais PVN" v-model="form.reverse_vat"/>
            </div>

            <div class="col-span-3">
                <Textarea name="notes" placeholder="Piezīmes" v-model="form.notes"
                          :errors="errors.notes"/>
            </div>


        </div>

        <div class="md:flex-shrink-0 flex items-center flex-wrap ml-auto gap-3 justify-end pt-4">
            <template v-if="!loading">
                <button type="button"
                        class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out"
                        @click.prevent="submit">
                    Saglabāt
                </button>
            </template>
            <template v-else>
                <Loading/>
            </template>

            <button @click.prevent="hideEditItemForm" type="button"
                    class="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-500 shadow-sm text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0">
                Atcelt
            </button>

        </div>

    </form>
</template>

<script>
import {mapGetters} from "vuex";
import Input from "@/components/Components/Input";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";
import Checkbox from "@/components/Components/Checkbox";
import Radio from "../Components/Radio";
import Textarea from "../Components/Textarea";

export default {
    name: "EditCreditNoteDetails",
    components: {
        Input,
        Loading,
        Checkbox,
        Radio,
        Textarea
    },
    props: ['item'],
    computed: {
        ...mapGetters({
            inputData: "catalogItemInputData",
            loading: "loading",
            errors: 'errors',
        }),
        formValidation() {
            return {
                date: {
                    rules: ['required']
                },
            }
        },
    },
    created() {
        this.form = this.item
    },
    methods: {

        hideEditItemForm() {
            this.$store.dispatch("removeAllFormsForDisplay");
            this.$store.dispatch(
                "getSingleCreditNote",
                this.$route.params.creditNoteId
            );
        },
        submit() {
            this.$Progress.start()
            if (Validation(this.formValidation, this.form)) {
                this.$store.dispatch("updateCreditNote", {
                    id: this.form.id,
                    date: this.form.full_date,
                    electronic_document: this.form.electronic_document,
                    lang: this.form.lang,
                    reverse_vat: this.form.reverse_vat,
                    notes: this.form.notes,
                });
            } else this.$Progress.fail()
        }
    },
}
</script>

<style scoped>

</style>