<template>
    <item-card>
        <template v-slot:title>
            <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                Pamatdati
                <Badge :text="item.accepted ? 'Akceptēts' : 'Sagatave'"
                       :className="item.accepted ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800' + 'mr-2 mb-2 md:mb-0'"
                />
            </h3>
        </template>
        <template v-slot:buttons>

            <Button icon="download-folder" tooltip="Lejuplādēt XML" :tooltipRight="true" @click="downloadXML" />

            <Button icon="briefcase-download" tooltip="Lejuplādēt PDF" :tooltipRight="true" @click="downloadPdf" />

            <template v-if="!displayForm('EditCreditNoteDetails') && !item.accepted">
                <Button icon="pencil" @click="showForm('EditCreditNoteDetails')"/>
            </template>

            <template v-if="!displayForm('EditCreditNoteDetails') && !item.accepted">
                <Button icon="delete" @click="deleteCreditNote"/>
            </template>
        </template>

        <template v-slot:content>

            <template v-if="item && !displayForm('EditCreditNoteDetails')">
                <ShowCreditNoteDetails :item="item" />

                <InvoiceOrderDetails :item="item.order"/>
            </template>

            <EditCreditNoteDetails v-if="displayForm('EditCreditNoteDetails')" :item="item"/>

        </template>
    </item-card>
</template>

<script>

import {defineAsyncComponent} from "vue";
import {mapGetters} from "vuex";
import EditCreditNoteDetails from "./EditCreditNoteDetails";
import Badge from "../Components/Badge";

const ItemCard = defineAsyncComponent(() =>
    import('@/components/Components/ItemCard'))
const ShowCreditNoteDetails = defineAsyncComponent(() =>
    import('@/components/CreditNotes/ShowCreditNoteDetails'))
const InvoiceOrderDetails = defineAsyncComponent(() =>
    import('@/components/Invoices/Invoice/InvoiceOrderDetails'))


export default {
    name: "CreditNoteDetails",
    components: {
        ItemCard,
        ShowCreditNoteDetails,
        InvoiceOrderDetails,
        EditCreditNoteDetails,
        Badge
    },
    props: ['item'],
    data: () => ({
        showDetails: false,
    }),
    computed: {
        ...mapGetters({
            formsForDisplay: 'formsForDisplay',
        }),
    },
    methods: {
        showForm(formName){
            this.$store.dispatch('addFormForDisplay', formName)
        },
        displayForm(formName){
            return this.formsForDisplay.includes(formName)
        },
        downloadPdf() {
            this.$store.dispatch('getCreditNotePdf', this.item)
        },
        downloadXML() {
            this.$store.dispatch('getCreditNoteXML', this.item)
        },
        deleteCreditNote(){
            if(confirm('Vai esi drošs?')){
                this.$Progress.start()
                this.$store.dispatch("deleteCreditNote", this.item.id)
            }
        }
    }
}
</script>

<style scoped>

</style>